import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'invoiceDtls',
  props: ['sourceType'],
  watch: {
    currentPage: function() {
      this.getInvoiceDtls();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInvoiceDtls();
    }
  },
  components: {},
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      invDtlsData: [],
      invDtlsFields: [
        {
          key: 'invoice_id',
          label: 'Invoice Id',
          class: 'text-center'
        },
        {
          key: 'invoice_num',
          label: 'Invoice Number',
          class: 'text-center'
        },
        {
          key: 'fromGstin',
          label: 'From GSTIN',
          class: 'text-center'
        },
        {
          key: 'toGstin',
          label: 'To GSTIN',
          class: 'text-center'
        },
        {
          key: 'invoiceDate',
          label: 'Invoice Date',
          class: 'text-center'
        },
        {
          key: 'irn',
          label: 'IRN',
          class: 'text-center'
        }
      ],
      invoiceNumber: null,
      invDtls: {
        num: null,
        id: null
      }
    };
  },
  mounted() {
    // if (this.sourceType.text) {
    //   this.getInvoiceDtls();
    // }
  },
  methods: {
    getInvoiceDtls() {
      const payload = {
        source_name: this.sourceType.text,
        invoice_num: this.invoiceNumber,
        _page: this.currentPage - 1,
        _limit: this.perPage
      };
      this.loader = true;
      this.$store
        .dispatch('eWayBill/getInvoiceDtls', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data.page;
            this.invDtlsData = results;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clear() {
      this.invoiceNumber = null;
    },
    rowSelected(item) {
      if (item.errorDesc.length) {
        this.$bvToast.toast(item.errorDesc, {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else {
        this.invDtls.num = item.invoice_num;
        this.invDtls.id = item.invoice_id;
        this.invDtls.gebId = item.gebId;
        this.$emit('mapInvDtls', this.invDtls);
      }
    }
  }
};
