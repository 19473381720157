import DatePicker from 'vue2-datepicker';
import { ModelSelect, BasicSelect } from 'vue-search-select';
export default {
  name: 'updateEwayBill',
  props: ['updateProps'],
  watch: {
    partBResCode: function() {
      this.updatePartBRemarks();
    },
    extnRsnCode: function() {
      this.extendEwbRemarks();
    },
    canRsnCode: function() {
      this.cancelEwbRemarks();
    }
  },
  components: { DatePicker, ModelSelect, BasicSelect },
  data() {
    return {
      loader: false,
      unsubscribe: null,
      updateType: null,
      editMode: false,
      selectedMode: {
        value: null,
        text: null
      },
      modeOptions: [],
      selectedStateBillTo: {
        value: null,
        text: null
      },
      vehicleNum: null,
      trnsportDocNum: null,
      trnsportDocDate: null,
      vehicleTypeOptions: [],
      selectedVehicleType: {
        value: null,
        text: null
      },
      ewayBillNum: null,
      transporterId: null,
      updatedTransporterId: null,
      canRsnCode: {
        text: null,
        value: null
      },
      canRsnCodeOptions: [],
      cancelRmrk: null,
      remainingDistance: 0,
      extnRsnCode: {
        text: null,
        value: null
      },
      extnRsnCodeOptions: [],
      extnRemarks: null,
      fromState: {
        text: null,
        value: null
      },
      fromStateOptions: [],
      partBResCode: {
        text: null,
        value: null
      },
      partBResCodeOptions: [],
      fromPlace: null,
      partBResRemarks: null,
      gebId: null,
      consignStatus: {
        text: null,
        valye: null
      },
      consignStatusOptions: [],
      transitType: {
        text: null,
        valye: null
      },
      transitTypeOptions: [],
      addressLine1: null,
      addressLine2: null
    };
  },
  mounted() {
    if (this.updateProps) {
      this.autoFilledData();
      this.formType = this.updateProps.formType;
      this.gebId = this.updateProps.gebId;
      if (this.formType === 'partB') {
        this.getPartBReasonCodeDtls();
      } else if (this.formType === 'canEwaybill') {
        this.getCancelEwbRsnCodeDtls();
      } else if (this.formType === 'extEwaybill') {
        this.getExtendEwbRsnCodeDtls();
        this.getConsignStatusDtls();
        this.getDtlsTransitTypeDtls();
      }
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.editMode = false;
          if (this.formType === 'partB') {
            this.updateEwayBillPartB();
          } else if (this.formType === 'transporterId') {
            // this.updateEwbTransporterId();
          } else if (this.formType === 'canEwaybill') {
            this.cancelEwayBill();
          } else if (this.formType === 'extEwaybill') {
            // this.extendEwayBill();
          }
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    updateEwayBillPartB() {
      const payloadData = {
        ewbNo: this.ewayBillNum,
        vehicleNo: this.vehicleNum,
        fromPlace: this.fromPlace,
        fromState: this.fromState.value,
        reasonCode: this.partBResCode.value,
        reasonRem: this.partBResRemarks,
        transDocNo: this.trnsportDocNum,
        transDocDate: this.trnsportDocDate,
        transMode: this.selectedMode.value,
        vehicleType: this.selectedVehicleType.value
      };
      const payload = {
        payloadData: payloadData,
        gebId: this.gebId
      };
      this.loader = true;
      this.$store
        .dispatch('eWayBill/updateEwayBillPartB', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('getEwayBillById');
            this.$bvToast.toast(response.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
          } else {
            this.$bvToast.toast(response.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    cancelEwayBill() {
      const payloadData = {
        ewbNo: +this.ewayBillNum,
        cancelRsnCode: +this.canRsnCode.value,
        cancelRmrk: this.cancelRmrk
      };
      const payload = {
        payloadData: payloadData,
        gebId: this.gebId
      };
      this.loader = true;
      this.$store
        .dispatch('eWayBill/cancelEwayBill', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('getEwayBillById');
            this.$bvToast.toast(response.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
          } else {
            this.$bvToast.toast(response.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updatePartBRemarks() {
      if (this.partBResCode.text !== 'Others (Pls. Specify)') {
        this.partBResRemarks = this.partBResCode.text;
      } else {
        this.partBResRemarks = null;
      }
    },
    extendEwbRemarks() {
      if (this.extnRsnCode.text !== 'Others') {
        this.extnRemarks = this.extnRsnCode.text;
      } else {
        this.extnRemarks = null;
      }
    },
    cancelEwbRemarks() {
      if (this.canRsnCode.text !== 'Others') {
        this.cancelRmrk = this.canRsnCode.text;
      } else {
        this.cancelRmrk = null;
      }
    },
    getPartBReasonCodeDtls() {
      this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetPartBReasonCode')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.partBResCodeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCancelEwbRsnCodeDtls() {
      this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetCancelRsnCode')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.canRsnCodeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getExtendEwbRsnCodeDtls() {
      this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetExtendRsnCode')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.extnRsnCodeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getConsignStatusDtls() {
      this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetConsignStatus')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.consignStatusOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getDtlsTransitTypeDtls() {
      this.loader = true;
      this.$store
        .dispatch('eWayBill/getValueSetTransitType')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.page;
            const itemDetails = result.map(ele => {
              return {
                value: ele.value_code,
                text: ele.value_meaning
              };
            });
            this.transitTypeOptions = itemDetails;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    toUpperCaseGstin(type) {
      if (type === 'transPortId') {
        this.updatedTransporterId = this.updatedTransporterId.toUpperCase();
      } else if (type === 'vehicleNum') {
        this.vehicleNum = this.vehicleNum.toUpperCase();
      }
    },
    autoFilledData() {
      if (this.updateProps.formType === 'partB') {
        this.modeOptions = this.updateProps.mode;
        this.vehicleTypeOptions = this.updateProps.vehicleType;
        this.selectedVehicleType = {
          text: this.updateProps.selectedVehicleType.text,
          value: this.updateProps.selectedVehicleType.value
        };
        this.ewayBillNum = this.updateProps.ewbNo;
        this.selectedMode = {
          text: this.updateProps.selectedMode.text,
          value: this.updateProps.selectedMode.value
        };
        this.trnsportDocDate = this.updateProps.trnsDocDate;
        this.trnsportDocNum = this.updateProps.trnsDocNo;
        this.vehicleNum = this.updateProps.vehicleNum;
        this.fromState = {
          value: this.updateProps.fromState.value,
          text: this.updateProps.fromState.text
        };
        this.fromStateOptions = this.updateProps.fromStateOptions;
        this.fromPlace = this.updateProps.fromPlace;
      } else if (this.updateProps.formType === 'transporterId') {
        this.ewayBillNum = this.updateProps.ewbNo;
        this.transporterId = this.updateProps.trnsId;
      } else if (this.updateProps.formType === 'canEwaybill') {
        this.ewayBillNum = this.updateProps.ewbNo;
      } else if (this.updateProps.formType === 'extEwaybill') {
        this.ewayBillNum = this.updateProps.ewbNo;
        this.modeOptions = this.updateProps.mode;
        this.vehicleTypeOptions = this.updateProps.vehicleType;
        this.selectedVehicleType = {
          text: this.updateProps.selectedVehicleType.text,
          value: this.updateProps.selectedVehicleType.value
        };
        this.selectedMode = {
          text: this.updateProps.selectedMode.text,
          value: this.updateProps.selectedMode.value
        };
        this.trnsportDocDate = this.updateProps.trnsDocDate;
        this.trnsportDocNum = this.updateProps.trnsDocNo;
        this.vehicleNum = this.updateProps.vehicleNo;
        this.fromState = {
          value: this.updateProps.fromState.value,
          text: this.updateProps.fromState.text
        };
        this.fromStateOptions = this.updateProps.fromStateOptions;
        this.fromPlace = this.updateProps.fromPlace;
        this.addressLine1 = this.updateProps.fromAddr1;
        this.addressLine2 = this.updateProps.fromAddr2;
      }
    },
    remainDisCheck() {
      if (+this.remainingDistance > +this.updateProps.transDistance) {
        this.$bvToast.toast(
          'Remaining distance should not be more than the actual distance.',
          {
            title: 'Alert',
            variant: 'danger',
            solid: true
          }
        );
        this.remainingDistance = 0;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
